'use client'
import { useState } from 'react'
import { VStack } from '@chakra-ui/react'
import type { ChangePasswordSubmitData } from 'forms'
import { ChangePasswordForm } from 'forms'
import { useUser } from 'auth'
import { fetchJson } from 'api'
import { useAccountContext } from '../hooks/useAccountContext'

const LoggedChangePassword = () => {
  const { user } = useUser()
  const [errorMsg, setErrorMsg] = useState('')
  const { paths } = useAccountContext()

  if (!user) {
    return null
  }

  const handleUpdatePassword = async ({ password, oldPassword }: ChangePasswordSubmitData) => {
    try {
      if (!oldPassword) {
        setErrorMsg('Missing params')
        return
      }

      await fetchJson(paths.resetPassword, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: user.customer?.email,
          oldPassword,
          newPassword: password
        })
      })
    } catch (e) {
      setErrorMsg((e as any).message)
    }
  }

  return (
    <VStack align="flex-start" justify="flex-start" spacing={12} w="full">
      <ChangePasswordForm
        error={errorMsg}
        onFormSubmit={handleUpdatePassword}
        w={{
          base: 'full',
          md: '80%'
        }}
        withOldPassword
      />
    </VStack>
  )
}

export default LoggedChangePassword
