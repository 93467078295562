import { FC } from 'react'

import { useSelector } from 'shared-redux'

import { useSlideOut } from '#/src/custom/controllers'
import { selectSearchProducts } from 'shared-redux/state/slices/searchProducts'
import {
  ProductListSearchMoreFilters,
  useProductListSearchFilter
} from 'storefront-modules/products/components/product-list-search'

export interface ISlideOutMoreFiltersSearch {}

const SlideOutMoreFiltersSearch: FC<ISlideOutMoreFiltersSearch> = () => {
  const searchProductsState = useSelector(selectSearchProducts)
  const { closeSlideOut } = useSlideOut()

  const {
    updateSearchDelayed,
    isLoading,
    clearFilters,
    updateCategoryFilterItemSelected,
    updateBrandFilterItemSelected,
    updateSelectedPriceRange,
    updateOptionFilterItemSelected
  } = useProductListSearchFilter()

  return (
    <ProductListSearchMoreFilters
      onSubmit={closeSlideOut}
      showSubmit
      {...{
        updateSearchDelayed,
        isLoading,
        clearFilters,
        updateCategoryFilterItemSelected,
        updateBrandFilterItemSelected,
        updateSelectedPriceRange,
        updateOptionFilterItemSelected,
        searchProductsState
      }}
    />
  )
}

export default SlideOutMoreFiltersSearch
