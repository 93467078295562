'use client'
import { AnimatePresence, motion } from 'framer-motion'
import { usePathname } from 'next/navigation'
import type { FC } from 'react'
import { Box } from '@chakra-ui/react'

const variants = {
  fadeIn: {
    y: 0,
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  inactive: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  },
  fadeOut: {
    opacity: 0,
    y: -0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut'
    }
  }
}

interface ITransitionEffectProps {
  children: any
}

const TransitionEffect: FC<ITransitionEffectProps> = ({ children }) => {
  const pathname = usePathname()

  return (
    <Box overflow="hidden">
      <AnimatePresence exitBeforeEnter initial={false}>
        <motion.div
          animate="inactive"
          exit="fadeOut"
          initial="fadeIn"
          key={pathname}
          variants={variants}>
          {children}
        </motion.div>
      </AnimatePresence>
    </Box>
  )
}

export default TransitionEffect
