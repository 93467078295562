'use client'
import { useRouter } from 'next/navigation'
import useSWR from 'swr'
import { useEffect } from 'react'
import type { User } from '../utils'

export default function useUser({ redirectTo = '', redirectIfFound = false } = {}) {
  const { data: user, mutate: mutateUser, isLoading, isValidating } = useSWR<User>('/api/auth/user')
  const router = useRouter()

  useEffect(() => {
    // if no redirect needed, just return (example: already on /dashboard)
    // if user data not yet there (fetch in progress, logged in or not) then don't do anything yet
    if (!redirectTo || !user) return

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !user.isLoggedIn) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && user.isLoggedIn)
    ) {
      router.push(redirectTo)
    }
  }, [user, redirectIfFound, redirectTo, router])

  return { user, mutateUser, isLoading, isValidating }
}
