import type { FC } from 'react'
import { useMemo } from 'react'
import { HStack, Stack, VStack } from '@chakra-ui/react'
import { GrandText, ImageBox } from 'ui'
import { commaSeparator } from 'shared-utils'
import { getProductImages } from 'api'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'
import type { InfoCartItemProps } from './types'

export interface InfoCartItemOverrides {
  currency?: string
}

type CustomInfoCartItemProps = Pick<InfoCartItemProps, 'cartItem' | 'size'>

const dictOfSizes = {
  sm: {
    image: {
      w: {
        base: 10,
        md: 12
      },
      h: {
        base: 10,
        md: 12
      }
    },
    itemTitle: {
      fontSize: 'sm'
    },
    itemPrice: {
      fontSize: {
        base: 'xs',
        md: 'sm'
      }
    },
    itemQuantity: {
      fontSize: 'xs'
    }
  },
  md: {
    image: {
      w: { base: 18, md: 20 },
      h: { base: 18, md: 20 }
    },
    itemTitle: {
      fontSize: 'md'
    },
    itemPrice: {
      fontSize: 'md'
    },
    itemQuantity: {
      fontSize: 'sm'
    }
  },
  lg: {
    image: {
      w: { base: '16vw', md: 28 },
      h: { base: '16vw', md: 28 }
    },
    itemTitle: {
      fontSize: 'lg'
    },
    itemPrice: {
      fontSize: 'lg'
    },
    itemQuantity: {
      fontSize: 'md'
    }
  }
}

const InfoCartItem: FC<CustomInfoCartItemProps> = ({ cartItem, size = 'sm' }) => {
  const { mainImageUrl } = getProductImages(cartItem.medias)
  const overrides = useOverridesContext<keyof InfoCartItemOverrides>()

  const sizes = useMemo(() => dictOfSizes[size], [size])

  return (
    <VStack className="InforCartItem" spacing={4} w="100%">
      <Stack direction={{ base: 'column', sm: 'row' }} w="100%">
        <GrandText
          flex={1}
          fontSize={{
            base: 'xs',
            md: 'sm'
          }}
          isTruncated
          w={{
            base: '100%',
            sm: 'auto'
          }}>
          {cartItem.name}
        </GrandText>

        <HStack spacing={4} w="full">
          <ImageBox
            alignSelf="flex-start"
            imageProps={{
              alt: cartItem.name,
              title: cartItem.name,
              src: mainImageUrl,
              style: {
                objectFit: 'contain'
              },
              sizes: '7em'
            }}
            {...sizes.image}
          />
          <VStack flex={1} w="full">
            <HStack
              justifyContent={{
                base: 'flex-end',
                sm: 'initial'
              }}
              w="100%">
              <GrandText
                display={{ base: 'none', sm: 'initial' }}
                flexGrow={1}
                w="auto"
                {...sizes.itemTitle}>
                {cartItem.name}
              </GrandText>

              <HStack align="center" flexShrink={0}>
                <GrandText flexGrow={1} fontWeight="medium" textAlign="center" {...sizes.itemPrice}>
                  {cartItem.price ? commaSeparator(cartItem.price) : 0}{' '}
                  {overrideText(Currency.SEK, overrides?.currency)}
                </GrandText>

                <GrandText {...sizes.itemQuantity}>x {cartItem.qty}</GrandText>
              </HStack>
            </HStack>
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  )
}

export default InfoCartItem
