import { FC } from 'react'
import CustomContact, { CustomContactProps } from '#/src/custom/components/CustomContact'

export interface ModalContactProps extends CustomContactProps {}

const ModalContact: FC<ModalContactProps> = ({ ...props }) => {
  return <CustomContact {...props} />
}

export default ModalContact
