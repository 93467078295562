import { Button, VStack } from '@chakra-ui/react'
import { BiCart } from 'react-icons/bi'
import useSWR from 'swr'
import type { OrderDTO, PaginatedResponse } from 'ecosystem'
import { Pagination, usePagination } from 'pagination'
import { scrollToTop } from 'shared-utils'
import { EmptyMessage, NextLink } from 'ui'
import { fetchJson } from 'api'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useAccountContext } from '../hooks/useAccountContext'
import CustomerOrderItem from './CustomerOrderItem'

export interface CustomerOrdersOverrides {
  noOrdersMessage?: string
  noOrdersBtnLabel?: string
}

const CustomerOrders = () => {
  const { onOpenOrderDetail, paths } = useAccountContext()
  const { data: response, mutate: mutateOrders } = useSWR<PaginatedResponse<OrderDTO[]>>(
    paths.getOrders
  )
  const overrides = useOverridesContext<keyof CustomerOrdersOverrides>()

  const pagination = usePagination({
    totalElements: response?.totalElements || 0,
    totalPages: response?.totalPages || 1,
    onFetchData: async (newPage) => {
      try {
        await mutateOrders(
          await fetchJson(`${paths.getOrders}?page=${newPage}`, {
            method: 'GET'
          }),
          false
        )
      } catch (e) {
        // eslint-disable-next-line no-console -- We want to warn in console if smt happened
        console.warn(e)
      }
      scrollToTop()
    }
  })

  const handleOpenOrderDetail = async (o: OrderDTO) => {
    const updatedOrderDetail: OrderDTO = await fetchJson(
      `${paths.getOrderDetail}?orderId=${o.id}`,
      {
        method: 'GET'
      }
    )
    await onOpenOrderDetail(updatedOrderDetail)
  }

  if (!response?.content.length) {
    return (
      <EmptyMessage
        extraComponent={
          <NextLink href="/">
            <Button aria-label="Fortsätt shoppa">
              {overrideText('Fortsätt shoppa', overrides?.noOrdersBtnLabel)}
            </Button>
          </NextLink>
        }
        fontSize="xl"
        icon={<BiCart size={40} />}
        text={overrideText(
          'Du har inte shoppat hos oss än. Blir den dagen idag?',
          overrides?.noOrdersMessage
        )}
        textAlign="center"
      />
    )
  }

  return (
    <VStack minH="400px">
      <VStack flexGrow={1} w="full">
        {response.content.map((order) => (
          <CustomerOrderItem
            cursor="pointer"
            customerOrder={order}
            key={order.id}
            onClick={() => {
              void handleOpenOrderDetail(order)
            }}
          />
        ))}
      </VStack>
      <Pagination length={response.content.length} options={pagination} />
    </VStack>
  )
}

export default CustomerOrders
