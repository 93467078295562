import { FC } from 'react'
import { VStack } from '@chakra-ui/react'
import CartItems from 'storefront-modules/cart/components/CartItems'
import { useCustomCart } from '#/src/custom/cart/useCustomCart'

interface ISlideOutCartProps {}

const SlideOutCart: FC<ISlideOutCartProps> = () => {
  const { cartItems } = useCustomCart()

  return (
    <VStack
      borderColor="none"
      maxH="20vh"
      overflowX="hidden"
      overflowY="auto"
      py={5}
      spacing={10}
      sx={{
        scrollbarWidth: 'none'
      }}
      w="100%">
      <CartItems {...{ cartItems }} />
    </VStack>
  )
}
export default SlideOutCart
