import { useContext } from 'react'
import { AccountContext } from '../context/AccountContext'

export const useAccountContext = () => {
  const ctx = useContext(AccountContext)

  if (!ctx) {
    throw Error('No context provider was found. Wrap your app with the AuthContextProvider')
  }

  return { ...ctx }
}
