import type { FC } from 'react'
import { SearchSlideOut } from 'storefront-modules/search'
import { useCustomSearch } from '#/src/custom/search/hooks/useCustomSearch'
import { useSlideOut } from '#/src/custom/controllers'

interface CustomSlideOutSearchProps {}

const CustomSlideOutSearch: FC<CustomSlideOutSearchProps> = ({}) => {
  const customSearch = useCustomSearch()
  const { closeSlideOut } = useSlideOut()

  return <SearchSlideOut onClear={closeSlideOut} {...customSearch} />
}

export default CustomSlideOutSearch
