'use client'
import { useCallback, useMemo, useState } from 'react'
import { paginationFn } from '../utils'
import type { PaginationOptions } from '../components/Pagination'

interface IUsePagination {
  totalPages: number
  totalElements: number
  onFetchData: (newPage: number) => Promise<void>
}

const usePagination = ({ totalPages, totalElements, onFetchData }: IUsePagination) => {
  const [page, setPage] = useState<number>(1)

  const paginator = useCallback(() => paginationFn(page, totalPages), [page, totalPages])

  const pagination: PaginationOptions = useMemo(
    () => ({
      page,
      totalPages: totalPages || 1,
      totalElements: totalElements || 0,
      paginator,
      fetchData: async (newPage) => {
        await onFetchData(newPage)
        setPage(newPage)
      }
    }),
    [page, totalPages, totalElements, paginator, onFetchData]
  )

  return pagination
}

export default usePagination
