'use client'
import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  ReactZoomPanPinchRef,
  TransformComponent as PinchZoomTransformComponent,
  TransformWrapper as PinchZoomTransformWrapper
} from 'react-zoom-pan-pinch'
import { Box, Text, VStack } from '@chakra-ui/react'

import useZoom from 'ui/hooks/useZoom'

import ModalPhotoGalleryImage from './ModalPhotoGalleryImage'
import ModalPhotoGalleryControls from './ModalPhotoGalleryControls'
import { ProductMedia } from 'ecosystem'

export interface IModalPhotoGalleryProps {
  selectedMediaIndex: number
  medias: Partial<ProductMedia>[]
}

const ModalPhotoGallery: FC<IModalPhotoGalleryProps> = ({ medias, selectedMediaIndex }) => {
  const mobileZoomTransformWrapperRef = useRef<ReactZoomPanPinchRef | null>(null)

  const [currentMediaIndex, setCurrentMediaIndex] = useState(selectedMediaIndex)

  const selectedMedia = useMemo(() => {
    if (medias.length) {
      return medias[currentMediaIndex]
    }

    return null
  }, [medias, currentMediaIndex])

  const handlePhotoDecrement = () => {
    setCurrentMediaIndex((prevState) => {
      if (prevState === 0) {
        return prevState
      } else if (prevState <= medias.length) {
        return prevState - 1
      }

      return prevState
    })
  }

  const handlePhotoIncrement = () => {
    setCurrentMediaIndex((prevState) => {
      if (prevState >= medias.length) {
        return prevState
      } else if (prevState < medias.length - 1) {
        return prevState + 1
      }

      return prevState
    })
  }

  const {
    containerAttrs: desktopZoomContainerAttrs,
    targetAttrs: desktopZoomTargetAttrs,
    setIsZoomed: setIsDesktopZoomed
  } = useZoom()

  useEffect(() => {
    setIsDesktopZoomed(false)

    if (mobileZoomTransformWrapperRef.current) {
      mobileZoomTransformWrapperRef.current?.resetTransform()
    }
  }, [selectedMedia, setIsDesktopZoomed])

  const renderZoomImageDesktop = () => (
    <Box flex={1} p={4} w="full" {...desktopZoomContainerAttrs}>
      <ModalPhotoGalleryImage
        {...desktopZoomTargetAttrs}
        imageProps={{
          src: selectedMedia?.url,
          sizes: '200vw'
        }}
      />
    </Box>
  )

  const renderZoomImageMobile = () => (
    <PinchZoomTransformWrapper
      doubleClick={{
        step: 1
      }}
      maxScale={4}
      ref={mobileZoomTransformWrapperRef}>
      <PinchZoomTransformComponent
        contentStyle={{ width: '100%', height: '100%' }}
        wrapperStyle={{ width: '100%', flex: '1' }}>
        <ModalPhotoGalleryImage
          h="100%"
          imageProps={{
            src: selectedMedia?.url,
            sizes: '400vw'
          }}
          w="100%"
        />
      </PinchZoomTransformComponent>
    </PinchZoomTransformWrapper>
  )

  return (
    <VStack h="100%" left={0} p={4} position="absolute" top={0} w="100%">
      <ModalPhotoGalleryControls
        onNextClick={handlePhotoIncrement}
        onPrevClick={handlePhotoDecrement}>
        <Text>
          {currentMediaIndex + 1} / {medias.length}
        </Text>
      </ModalPhotoGalleryControls>

      {isMobile ? renderZoomImageMobile() : renderZoomImageDesktop()}
    </VStack>
  )
}

export default ModalPhotoGallery
