'use client'
import type { FC } from 'react'
import { useState } from 'react'
import { BiChevronDown, BiChevronUp } from 'react-icons/bi'
import type { StackProps } from '@chakra-ui/react'
import { Button, HStack, VStack } from '@chakra-ui/react'
import { GrandHeading, GrandNextLink, GrandSwitch, GrandText } from 'ui'
import useCookies from '../hooks/useCookies'

interface CookiePolicyProps {
  onConfig: () => void
}
const CookiePolicy: FC<CookiePolicyProps> = ({ onConfig }) => {
  // hooks
  const { setConsent } = useCookies()

  //local state
  const [showNecessaryDetails, setShowNecessaryDetails] = useState(false)
  const [showMarketingDetails, setShowMarketingDetails] = useState(false)
  const [acceptedMarketingCookies, setAcceptedMarketingCookies] = useState(false)

  //handlers

  const handleClick = () => {
    setConsent(acceptedMarketingCookies)
    onConfig()
  }

  return (
    <VStack fontSize={{ base: 'sm', md: 'md' }} h="100%" pb={6} px={2} spacing={4} w="100%">
      Please select and accept your Cookies Group
      <VStack align="flex-start" spacing={1} w="100%">
        <GrandHeading as="h5" fontSize={{ base: 'md', md: 'xl' }} title="NÖDVÄNDINGA" />
        <GrandText>
          Nödvändiga cookies möjliggör webbplatsens grundläggande funktionalitet. Utan dessa cookies
          kan webbplatsen inte fungera korrekt. De hjälper till att göra en webbplats användbar
          genom att aktivera grundläggande funktioner.
        </GrandText>
        <NecessaryCookies
          h={showNecessaryDetails ? { base: 'auto', md: 300 } : 0}
          overflowY="auto"
          py={showNecessaryDetails ? 6 : 0}
          transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
        />
        <Button
          alignSelf="flex-start"
          aria-label={`${showNecessaryDetails ? 'show' : 'hide'}-service-details`}
          bg="transparent"
          color="text.mild"
          onClick={() => {
            setShowNecessaryDetails(!showNecessaryDetails)
          }}
          rightIcon={showNecessaryDetails ? <BiChevronUp /> : <BiChevronDown />}
          textDecoration="none">
          {showNecessaryDetails ? 'gömma Specifikationer' : 'Specifikationer'}
        </Button>
      </VStack>
      <VStack align="flex-start" spacing={1} w="100%">
        <HStack justify="space-between" w="100%">
          <GrandHeading as="h5" fontSize={{ base: 'md', md: 'xl' }} title="MARKNADSFÖRING" />
          <GrandSwitch
            aria-label="give-cons-to-marketing-cookies"
            id="marketing-cookies-switch"
            isChecked={acceptedMarketingCookies}
            label="aktivera"
            labelProps={{
              fontSize: { base: 'sm', md: 'md' }
            }}
            onChange={() => {
              setAcceptedMarketingCookies(!acceptedMarketingCookies)
            }}
          />
        </HStack>

        <GrandText>
          Marknadsföringskakor används för att spåra och samla besökarnas handlingar på webbplatsen.
          Cookies lagrar användardata och beteendeinformation, vilket gör att annonseringstjänster
          kan rikta in sig mot fler målgrupper. Mer anpassad användarupplevelse kan också
          tillhandahållas enligt insamlad information
        </GrandText>
        <MarketingCookies
          h={showMarketingDetails ? { base: 'auto', md: 300 } : 0}
          overflowY="auto"
          py={showMarketingDetails ? 6 : 0}
          transition="all 0.1s cubic-bezier(.17,.67,.83,.67)"
        />
        <Button
          alignSelf="flex-start"
          aria-label={`${showMarketingDetails ? 'show' : 'hide'}-service-details`}
          bg="transparent"
          color="text.mild"
          onClick={() => {
            setShowMarketingDetails(!showMarketingDetails)
          }}
          rightIcon={showMarketingDetails ? <BiChevronUp /> : <BiChevronDown />}
          textDecoration="none">
          {showMarketingDetails ? 'gömma Specifikationer' : 'Specifikationer'}
        </Button>
      </VStack>
      <HStack justify="flex-end" w="100%">
        <Button aria-label="configure-cookie-cons" name="Konfigurera" onClick={handleClick}>
          Spara Valen
        </Button>
      </HStack>
    </VStack>
  )
}

const NecessaryCookies: FC<StackProps> = (props) => (
  <VStack fontSize="xs" px={{ base: 2, md: 6 }} spacing={6} w="100%" {...props}>
    <VStack align="flex-start" spacing={2} w="100%">
      <p>
        We use a cookie to store your cons and enable/disable the marketing cookies accordingly.
      </p>
      <ul>
        <li> user_cookie_consent (1 year) </li>
      </ul>
    </VStack>

    <VStack align="flex-start" spacing={2} w="100%">
      <p>
        Our checkout solution is provided by Klarna (Klarna AB, Sveavägen 46, 111 34 Stockholm,
        Sweden) and uses cookies to offer you the best possible, tailored, online experience when
        you use Klarna&apos;s checkout. The exact cookies and purposes for which they are used can
        be found here:&nbsp;
        <GrandNextLink
          fontWeight="bold"
          href="https://cdn.klarna.com/1.0/shared/content/policy/cookie/de_de/checkout.pdf">
          Cookie policy (pdf)
        </GrandNextLink>
      </p>
    </VStack>
    <VStack align="flex-start" spacing={2} w="100%">
      <p>
        Service: Imbox.se
        <br />
        Purpose: The service uses cookies to save settings and statistics, for example, if the
        service should be raised/lowered, the number of times the service is visited, a visitor’s
        traffic source, which pages are popular and less popular, etc., as well as for identifying
        trusted traffic and making the user experience as good as possible. By knowing how users use
        the service, our clients can develop and improve the service according to their needs which
        provides a customized and personal experience.
      </p>
      <ul>
        <li> imboxDisabled (One year) </li>
        <li> imboxInteraction (30 days) </li>
        <li> imboxStats (20 minutes) </li>
        <li> imboxConsent (30 days) </li>
        <li> imbox-cons session) </li>
        <li> imbox-pendingMessages (session) </li>
        <li> imbox-navigation (session) </li>
        <li> imbox-search (session) </li>
        <li> imbox-referrer (session) </li>
        <li> imbox-widget (session) </li>
        <li> imbox-hasOpenedOnce (session) </li>
      </ul>
    </VStack>
    <VStack align="flex-start" spacing={2} w="100%">
      <p>
        we use Google Maps to display the location of our stores. For a detailed description of the
        purposes and practices of Google cookies, please refer to the &nbsp;
        <GrandNextLink fontWeight="bold" href="https://policies.google.com/technologies/cookies">
          Google cookies policy
        </GrandNextLink>
        &nbsp;page.
      </p>
    </VStack>
  </VStack>
)

const MarketingCookies: FC<StackProps> = (props) => (
  <VStack fontSize="xs" px={{ base: 2, md: 6 }} spacing={6} {...props}>
    <VStack align="flex-start" spacing={2} w="100%">
      <p>
        Service: Google Analytics <br />
        Purpose: Google Analytics is a simple, easy-to-use tool that helps website owners measure
        how users interact with website content. As a user navigates between web pages, Google
        Analytics provides website owners JavaScript tags (libraries) to record information about
        the page a user has seen, for example the URL of the page. <br />
      </p>
      <ul>
        <li>_ga (2 years)</li>
        <li>_ga_[container-id] (session cookie)</li>
      </ul>
    </VStack>
  </VStack>
)

export default CookiePolicy
