import { useMemo } from 'react'
import type { SideNavSection } from 'ui/storefront'
import { SideNavigation } from 'ui/storefront'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { UseSearchParamsSuspense } from 'ui/wrappers'
import { type ReadonlyURLSearchParams } from 'next/navigation'
import { useAccountContext } from '../hooks/useAccountContext'
import Profile from './Profile'
import OrdersComponent from './Orders'
import LoggedChangePassword from './LoggedChangePassword'

export interface AccountHandlerOverrides {
  accountHandlerAccountTitle?: string
  accountHandlerOrdersTitle?: string
  accountHandlerProfileTitle?: string
  accountHandlerChangePasswordTitle?: string
}

const AccountHandler = ({ searchParams }: { searchParams?: ReadonlyURLSearchParams | null }) => {
  const overrides = useOverridesContext<keyof AccountHandlerOverrides>()
  const { defaultMenuId, defaultMenuIdSearchParamsName } = useAccountContext()

  const defaultId = useMemo(() => {
    let menuId

    if (typeof defaultMenuId === 'string') {
      menuId = defaultMenuId
    } else if (defaultMenuIdSearchParamsName && searchParams) {
      menuId = searchParams.get(defaultMenuIdSearchParamsName) ?? '0'
    }

    if (typeof menuId === 'string') {
      return parseInt(menuId)
    }

    return 0
  }, [defaultMenuId, defaultMenuIdSearchParamsName, searchParams])

  const navSections: SideNavSection[] = [
    {
      title: overrideText('Konto', overrides?.accountHandlerAccountTitle),
      items: [
        {
          id: 0,
          title: overrideText('Ordrar', overrides?.accountHandlerOrdersTitle),
          component: <OrdersComponent />
        },
        {
          id: 1,
          title: overrideText('Profil', overrides?.accountHandlerProfileTitle),
          component: <Profile />
        },
        {
          id: 2,
          title: overrideText('Ändra lösenord', overrides?.accountHandlerChangePasswordTitle),
          component: <LoggedChangePassword />
        }
      ]
    }
  ]

  return <SideNavigation {...{ navSections }} {...{ defaultId }} />
}

const AccountHandlerSuspended = () => (
  <UseSearchParamsSuspense fallback={<AccountHandler />}>
    {(searchParams) => <AccountHandler searchParams={searchParams} />}
  </UseSearchParamsSuspense>
)
export default AccountHandlerSuspended
