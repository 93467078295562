'use client'
import type { FC, ReactNode } from 'react'
import { customTheme } from '#/src/custom/styles/theme'
import { ChakraProvider } from '@chakra-ui/react'

interface ChakraProviderNextProps {
  children: ReactNode
}

const ChakraProviderNext: FC<ChakraProviderNextProps> = ({ children }) => {
  return (
    <ChakraProvider resetCSS theme={customTheme}>
      {children}
    </ChakraProvider>
  )
}

export default ChakraProviderNext
