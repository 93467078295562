import { FC, Fragment, JSX } from 'react'
import { Box, Button, ButtonProps, Divider, HStack, VStack } from '@chakra-ui/react'
import { customTheme } from '#/src/custom/styles/theme'
import { ContentContainer, GrandNextLink, useUIIcon } from 'ui'
import appConfig from '#/src/appConfig'
import { TopNavPanelItem } from 'ecosystem'

interface IMobileMenuSlideOutFooterProps {}

const MobileMenuSlideOutFooter: FC<IMobileMenuSlideOutFooterProps> = () => {
  const panelItems = appConfig.topNavPanel?.items ?? []

  const navbarActions: { component: JSX.Element; id: number }[] = []

  if (!panelItems.length && !navbarActions.length) {
    return null
  }

  return (
    <ContentContainer direction="column" p={0} spacing={10}>
      <VStack align="flex-start" w="100%">
        <Divider />
        {panelItems?.map((item, index) => (
          <Fragment key={item.label + index}>
            {item.href ? (
              <Box w="100%">
                <GrandNextLink aria-label={item.label} href={item.href}>
                  <FooterMenuButton {...{ item }} />
                </GrandNextLink>
              </Box>
            ) : (
              <FooterMenuButton {...{ item }} />
            )}
          </Fragment>
        ))}
      </VStack>
      <HStack justifyContent="flex-end" py={2} spacing={4} w="100%">
        {navbarActions.map(({ component, id }) => (
          <Box key={id}>{component}</Box>
        ))}
      </HStack>
    </ContentContainer>
  )
}

const FooterMenuButton: FC<ButtonProps & { item: TopNavPanelItem }> = ({ item, ...props }) => {
  const Icon = useUIIcon(item.icon)

  return (
    <Button
      _focus={{
        border: 'none'
      }}
      _hover={{
        bg: 'background.surface'
      }}
      aria-label={item.label}
      borderRadius="sm"
      color="text.default"
      fontSize="sm"
      fontWeight={400}
      size="sm"
      transition={customTheme.transition.default}
      variant="ghost"
      w="100%"
      {...(!item?.href && {
        pointerEvents: 'none',
        color: 'text.mild'
      })}
      {...props}>
      <HStack justify="flex-start" spacing={4} w="100%">
        <Icon />
        <Box textAlign="center">{item.label}</Box>
      </HStack>
    </Button>
  )
}

export default MobileMenuSlideOutFooter
