import type { FC, ReactElement } from 'react'
import { ChakraProps, LinkProps, useStyleConfig } from '@chakra-ui/react'
import { Flex, Grid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import type { FooterItem, SocialMediaItem } from 'ecosystem'
import GrandNextLink from 'ui/common/GrandNextLink'
import GrandSection from 'ui/storefront/layout/GrandSection'
import FooterColumn from 'ui/storefront/layout/FooterColumn'

interface IFooter {
  logo: ReactElement
  footerItems: FooterItem[]
  socialMediaItems: SocialMediaItem<LinkProps>[]
}

const FooterSocialMedia = dynamic(
  async () => {
    const f = await import('ui/storefront')
    return f.FooterSocialMedia
  },
  {
    ssr: false
  }
) as any

const CustomFooter: FC<IFooter> = ({ logo, footerItems, socialMediaItems }) => {
  const styles = useStyleConfig('CustomFooter') as ChakraProps
  const itemColSpan = useBreakpointValue({
    base: 4,
    md: 1
  })

  const socialDesktop = useBreakpointValue({
    base: 'none',
    md: 'initial'
  })

  const socialMobile = useBreakpointValue({
    base: 'initial',
    md: 'none'
  })

  return (
    <GrandSection position="relative" sectionId="footer" sx={styles}>
      <Flex h={20} justifyContent="center" w="100%">
        <GrandNextLink aria-label="home" href="/">
          {logo}
        </GrandNextLink>
      </Flex>

      <Grid gap={4} templateColumns="repeat(4, 1fr)">
        {footerItems.slice(0, 3).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key -- These items don't have an id
          <GridItem colSpan={itemColSpan} key={index} rowSpan={1}>
            <FooterColumn {...{ item }} />
          </GridItem>
        ))}

        <GridItem colSpan={itemColSpan} display={socialDesktop} rowSpan={2}>
          <VStack align="center" spacing={2}>
            <FooterSocialMedia align="center" items={socialMediaItems} />
          </VStack>
        </GridItem>

        {footerItems.slice(3).map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key -- These items don't have an id
          <GridItem colSpan={itemColSpan} key={index} rowSpan={1}>
            <FooterColumn {...{ item }} />
          </GridItem>
        ))}

        <GridItem colSpan={itemColSpan} display={socialMobile} rowSpan={2}>
          <VStack spacing={2} w="full">
            <FooterSocialMedia align="flex-start" items={socialMediaItems} />
          </VStack>
        </GridItem>
      </Grid>
    </GrandSection>
  )
}

export default CustomFooter
