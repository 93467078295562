import { DEFAULT_PAG_SIZE } from './constants'

export const paginationFn = (page: number, numPag: number, paginationLength = 5) => {
  if (numPag <= paginationLength) {
    return Array.from(Array(numPag), (_, index) => index + 1)
  }

  if (page === 1) {
    const arr: (string | number)[] = Array.from(Array(paginationLength), (_, index) => index + page)
    arr.push('next', numPag)
    return arr
  }
  if (page === 2) {
    const arr: (string | number)[] = Array.from(
      Array(paginationLength - 1),
      (_, index) => index + page
    )
    arr.unshift('prev', 1)
    arr.push('next', numPag)
    return arr
  }

  if (page === 3) {
    const arr: (string | number)[] = Array.from(
      Array(paginationLength - 2),
      (_, index) => index + page
    )
    arr.unshift('prev', 1, 2)
    arr.push('next', numPag)
    return arr
  }

  if (page >= numPag - 2) {
    const arr = Array.from(Array(1)).fill(numPag)
    arr.unshift(1, 'prev', numPag - 4, numPag - 3, numPag - 2, numPag - 1)
    return arr
  }

  const arr = Array.from(Array(1)).fill(page)
  arr.unshift(1, 'prev', page - 2, page - 1)
  arr.push(page + 1, page + 2, 'next', numPag)
  return arr
}

export const paginationPropsFactory = (page?: number, customSize?: number) => {
  const p = page || 1
  const size = customSize || DEFAULT_PAG_SIZE

  return {
    size,
    currentPage: p
  }
}
