import type { FC } from 'react'
import type { FlexProps } from '@chakra-ui/react'
import { Flex } from '@chakra-ui/react'
import { ChakraNextLink, GrandHeading } from 'ui'
import { fetchJson } from 'api'
import { useUser } from 'auth'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { useAccountContext } from '../hooks/useAccountContext'

export interface AccountControlOverrides {
  logout: string
}

type AccountControlsProps = FlexProps

const AccountControls: FC<AccountControlsProps> = ({ ...props }) => {
  const { paths, onLogout, userGreeting } = useAccountContext()
  const { mutateUser } = useUser()
  const overrides = useOverridesContext<keyof AccountControlOverrides>()

  const handleOnLogout = async () => {
    await mutateUser(await fetchJson(paths.logout, { method: 'POST' }), false)
    await onLogout()
  }

  return (
    <Flex align="center" h="full" justify="space-between" w="full" {...props}>
      <GrandHeading fontSize="md" headingTag="h6" title={userGreeting} />
      <ChakraNextLink
        flexShrink={0}
        fontSize="md"
        fontWeight="bold"
        href={paths.logout}
        id="logout"
        onClick={(e) => {
          e.preventDefault()
          void handleOnLogout()
        }}>
        {overrideText('Logga ut', overrides?.logout)}
      </ChakraNextLink>
    </Flex>
  )
}

export default AccountControls
