import { FC } from 'react'
import { Box } from '@chakra-ui/react'

export interface IModalGMapsProps {
  mapSrc: string
}

const ModalGMaps: FC<IModalGMapsProps> = ({ mapSrc }) => {
  return (
    <Box borderRadius="sm" h={{ base: '80vh', sm: '60vh' }} w="100%">
      <iframe
        height="100%"
        loading="lazy"
        //referrerpolicy='no-referrer-when-downgrade'
        src={mapSrc}
        style={{ border: 0 }}
        title="ModalGMaps"
        width="100%"
      />
    </Box>
  )
}

export default ModalGMaps
