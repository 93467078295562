import type { KeyboardEvent } from 'react'
import { useRouter } from 'next/navigation'

interface HookReturnType {
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>, callback?: () => void) => void
}

const useProductSearch = (): HookReturnType => {
  const router = useRouter()

  const submitSearch = (event: KeyboardEvent<HTMLInputElement>, callback: () => void) => {
    const target = event.target as HTMLInputElement
    if (target.value) {
      router.push(`/search?q=${target.value}`)
      callback()
    }
  }

  let delayedSearch: any
  // eslint-disable-next-line @typescript-eslint/no-empty-function -- We want to return void, so it's ok to silence
  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>, callback = () => {}) => {
    clearTimeout(delayedSearch)
    if (event.key === 'Enter') {
      submitSearch(event, callback)
      return
    }
    delayedSearch = setTimeout(() => {
      submitSearch(event, callback)
    }, 1500)
  }

  return {
    onKeyDown
  }
}

export default useProductSearch
