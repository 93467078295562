'use client'
import React, { FC, ReactNode } from 'react'

interface AppComponentProps {
  children: ReactNode
}

const AppComponent: FC<AppComponentProps> = ({ children }) => {
  return <>{children}</>
}

export default AppComponent
