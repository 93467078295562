import { useEffect, useRef } from 'react'
import type { BoxProps } from '@chakra-ui/react'
import { Box } from '@chakra-ui/react'

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref: any, onClickOutside: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside()
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line -- Only the ref should rerender this component to avoid infinite loops
  }, [ref])
}

/**
 * Component that alerts if you click outside of it
 */
interface IOutsideAlerterProps extends BoxProps {
  onClickOutside: () => void
}

function OutsideAlerter({ children, onClickOutside, ...props }: IOutsideAlerterProps) {
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onClickOutside)

  return (
    <Box ref={wrapperRef} {...props}>
      {children}
    </Box>
  )
}

export default OutsideAlerter
