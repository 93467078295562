import type { FC } from 'react'
import { GrandSection } from 'ui'
import type { OverridesContextType } from 'ui/lib/overrides'
import { OverridesContextProvider } from 'ui/lib/overrides'
import type { AccountsContextType } from '../context/types'
import { AccountContext } from '../context/AccountContext'
import AccountControls from './AccountControls'
import AccountHandler from './AccountHandler'

export type AccountPageConfig = AccountsContextType & {
  overrides?: OverridesContextType
}

interface AccountPageStdProps {
  config: AccountPageConfig
}

const AccountPageStd: FC<AccountPageStdProps> = ({
  config: { overrides, defaultMenuIdSearchParamsName = 'menuId', ...providerProps }
}) => {
  return (
    <OverridesContextProvider overrides={{ ...overrides }}>
      <AccountContext.Provider value={{ defaultMenuIdSearchParamsName, ...providerProps }}>
        <GrandSection sectionId="account">
          <AccountControls />
          <AccountHandler />
        </GrandSection>
      </AccountContext.Provider>
    </OverridesContextProvider>
  )
}

export default AccountPageStd
