import { FC } from 'react'
import { httpImageFactory } from 'api'
import { IImageBoxProps, ImageBox } from 'ui'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'

export type IModalPhotoGalleryImageProps = IImageBoxProps

const ModalPhotoGalleryImage: FC<IModalPhotoGalleryImageProps> = ({ imageProps, ...props }) => {
  const { src, style, ...otherImageProps } = imageProps
  const { defaultImgSrc } = useUIGlobalsContext()
  return (
    <ImageBox
      imageProps={{
        src: src ? httpImageFactory(src as string) : defaultImgSrc,
        style: {
          objectFit: 'contain',
          ...style
        },
        fill: true,
        sizes: '100vw',
        quality: 100,
        ...otherImageProps
      }}
      p={0}
      {...props}
    />
  )
}

export default ModalPhotoGalleryImage
