'use client'
import { FC } from 'react'
import { Heading, HStack } from '@chakra-ui/react'
import { MdClose } from 'react-icons/md'
import { useSlideOut } from '#/src/custom/controllers'
import { GrandIconButton } from 'ui'

interface ISlideOutCloseTitleProps {
  title: string
}

const SlideOutCloseTitle: FC<ISlideOutCloseTitleProps> = ({ title }) => {
  const { closeSlideOut } = useSlideOut()
  return (
    <HStack justify="space-between" w="100%">
      <Heading
        fontSize={{
          base: 'md',
          md: 'xl'
        }}
        isTruncated>
        {title}
      </Heading>

      <GrandIconButton
        aria-label="close"
        icon={<MdClose />}
        onClick={closeSlideOut}
        shadow="none"
        size="xs"
      />
    </HStack>
  )
}

export default SlideOutCloseTitle
