import { Button, HStack, Stack, useBreakpointValue } from '@chakra-ui/react'
import type { FC } from 'react'
import { Banner, GrandText } from 'ui'
import useCookies from '../hooks/useCookies'

export interface CookieBannerProps {
  openConfig: () => void
}

const CookieBanner: FC<CookieBannerProps> = ({ openConfig }) => {
  const { consent, setConsent } = useCookies()
  const handleConsent = () => {
    setConsent(true)
  }

  return (
    <Banner
      content={
        <Stack
          align="center"
          direction={{ base: 'column', md: 'row' }}
          fontSize={['xs', 'sm']}
          justify="space-between"
          spacing={{ base: 2, md: 4 }}
          w="100%">
          <GrandText flexGrow={1}>
            Vi använder cookies. Vi gör detta för att förbättra vår webbsidas funktionalitet,
            analysera sidans användning och kunna optimera din användarupplevelse. Genom att klicka
            på ”Godkänn” eller genom att fortsätta använda vår webbsida så godkänner du användningen
            av cookies. För mer information om hur vi hanterar personuppgifter, vänligen läs vår
            integritetspolicy.
          </GrandText>
          <HStack spacing={2}>
            <Button
              aria-label="configure-cookie-cons"
              bg="transparent"
              color="text.default"
              mx={6}
              onClick={openConfig}
              size={useBreakpointValue({ base: 'sm', md: 'md' })}>
              Konfigurera
            </Button>
            <Button
              aria-label="configure-cookie-cons"
              name="Konfigurera"
              onClick={handleConsent}
              size={useBreakpointValue({ base: 'sm', md: 'md' })}>
              Tillåt Cookies
            </Button>
          </HStack>
        </Stack>
      }
      isShown={consent === undefined}
      position="bottom"
    />
  )
}

export default CookieBanner
