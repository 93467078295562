import { FC } from 'react'

import { useSelector } from 'shared-redux'

import { useSlideOut } from '#/src/custom/controllers'
import appConfig from '#/src/appConfig'
import MoreFilters from 'storefront-modules/products/components/product-list-vertical/MoreFilters'
import { selectedProductState } from 'shared-redux/state'

export interface ISlideOutMoreFilters1 {}

const SlideOutMoreFilters1: FC<ISlideOutMoreFilters1> = () => {
  const productsState = useSelector(selectedProductState)
  const { closeSlideOut } = useSlideOut()

  return (
    <MoreFilters
      onSearchUpdate={closeSlideOut}
      pt={12}
      sliderDefaultValues={appConfig.sliderDefaultValues}
      sliderMaxPrice={appConfig.sliderMaxPrice}
      sortingOptions={appConfig.sortingOptions}
      {...{ productsState }}
    />
  )
}

export default SlideOutMoreFilters1
