'use client'
import { FC } from 'react'
import { SlideOutComponent, SlideOutFooter } from '#/src/custom/controllers/SlideOutChildren'
import { SlideOutContext, SlideOutStoreController, useSlideOut } from '..'
import { useSelector } from 'shared-redux'
import appConfig from '#/src/appConfig'
import { selectIsWindowOnTop } from 'shared-redux/state'

const SlideOutHandler: FC = () => {
  const { componentId, extraArgs, footerId } = useSlideOut()
  const isWindowOnTop = useSelector(selectIsWindowOnTop)

  return (
    <SlideOutStoreController
      context={SlideOutContext}
      footer={<SlideOutFooter {...{ footerId }} />}
      topGap={isWindowOnTop ? appConfig.topNavHeight.spacer : { base: '8vh', md: '14vh' }}>
      <SlideOutComponent {...{ componentId, extraArgs }} />
    </SlideOutStoreController>
  )
}

export default SlideOutHandler
