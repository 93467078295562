'use client'
import { FC } from 'react'
import { HStack, List, ListItem, Stack } from '@chakra-ui/react'
import { GrandHeading, GrandNextLink } from 'ui'
import { CategoryDTO } from 'ecosystem'
import { customTheme } from '#/src/custom/styles/theme'
import appConfig from '#/src/appConfig'
import { sortCategories } from 'shared-utils'
import { usePathname } from 'next/navigation'

interface ICategoryWithSubCategoriesProps {
  category: CategoryDTO
}

const CategoryWithSubCategories: FC<ICategoryWithSubCategoriesProps> = ({ category }) => {
  const pathname = usePathname()
  const subcategories = category.subCategories
    ? sortCategories([...category.subCategories]).splice(0, 5)
    : []

  return (
    <Stack spacing={2}>
      <HStack align="flex-start" spacing={1}>
        <List spacing={1}>
          <ListItem color="text.mild" fontSize="sm" transition={customTheme.transition.default}>
            <GrandNextLink aria-label={category.name} href={`/categories/${category.slug}`}>
              <GrandHeading
                cursor="inherit"
                fontSize="sm"
                title={category.name}
                {...(pathname?.includes(`/categories/${category.slug}`) && {
                  color: 'primary',
                  _hover: {
                    color: 'text.default'
                  }
                })}
                {...(pathname === `/categories/${category.slug}` && { fontWeight: 'bold' })}
              />
            </GrandNextLink>
          </ListItem>
          {subcategories.map((subCategory) => (
            <ListItem
              _hover={{
                color: 'text.default'
              }}
              color="text.mild"
              cursor="pointer"
              fontSize="sm"
              key={subCategory.id}
              transition={customTheme.transition.default}
              {...(pathname === `${appConfig.paths.categoriesUrlPath}/${subCategory.slug}` && {
                color: 'primary',
                fontWeight: 'bold'
              })}>
              <GrandNextLink aria-label={subCategory.name} href={`/categories/${subCategory.slug}`}>
                {subCategory.name}
              </GrandNextLink>
            </ListItem>
          ))}
        </List>
      </HStack>
    </Stack>
  )
}

export default CategoryWithSubCategories
