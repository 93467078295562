'use client'
import { FC } from 'react'
import { ModalContext, useModal } from '..'
import { ModalBasicController } from 'ui/lib/modal'
import { ModalComponent } from '#/src/custom/controllers/ModalChildren'

const ModalHandler: FC = () => {
  const { componentId, extraArgs } = useModal()

  return (
    <ModalBasicController context={ModalContext}>
      <ModalComponent {...{ componentId, extraArgs }} />
    </ModalBasicController>
  )
}

export default ModalHandler
