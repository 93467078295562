import { useSearch } from 'storefront-modules/search/hooks'
import { useGoogleAnalytics } from 'ui/lib/third-party'

export const useCustomSearch = () => {
  const GA = useGoogleAnalytics()

  const onSubmit = (value: string) => {
    GA.search(value)
  }

  return useSearch({
    config: {
      paths: {
        searchApi: '/api/search',
        searchPage: '/search'
      },
      onSubmit
    }
  })
}
