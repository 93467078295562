import { Flex, HStack, Link, Text, VStack } from '@chakra-ui/react'
import type { FC, ReactNode } from 'react'
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi'

export interface PaginationOptions {
  page: number
  totalPages: number
  totalElements: number
  paginator: () => any
  fetchData: (newPage: number) => void
}

export interface IPaginationProps {
  options: PaginationOptions
  length: number
}

const Pagination: FC<IPaginationProps> = ({ options, length, ...props }) => {
  const { page, totalPages, totalElements, paginator, fetchData } = options

  const style = (e: any) => {
    if (typeof e === 'string') {
      return {
        bg: 'transparent'
      }
    } else if (page === e) {
      return {
        bg: 'primary',
        color: 'text.light',
        fontWeight: 'bold'
      }
    }
    return {
      bg: 'transparent',
      border: 'sm'
    }
  }

  const getControls = (e: any) => {
    let p = e
    let children: ReactNode | null = e

    if (e === 'prev') {
      p = page - 1
      children = <BiChevronLeft size={15} />
    } else if (e === 'next') {
      p = page + 1
      children = <BiChevronRight size={15} />
    }

    return {
      p,
      children: <Flex align="center">{children}</Flex>
    }
  }

  return (
    <VStack pt={4} w="100%" {...props}>
      <Flex justify="flex-end" w="100%">
        <Text fontSize="sm">
          Page <strong>{page}</strong> of <strong>{totalPages}</strong>. Showing{' '}
          <strong>{length}</strong> of <strong>{totalElements}</strong> items.
        </Text>
      </Flex>

      <HStack justify="flex-end" spacing={2} w="100%">
        {paginator()?.map((e: any, index: number) => {
          const controls = getControls(e)
          return (
            <Link
              alignItems="center"
              borderRadius="sm"
              display="flex"
              fontSize="xs"
              h={8}
              justifyContent="center"
              key={index}
              onClick={() => {
                fetchData(controls.p)
              }}
              w={8}
              {...style(e)}>
              {controls.children}
            </Link>
          )
        })}
      </HStack>
    </VStack>
  )
}

export default Pagination
