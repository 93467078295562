'use client'
import type { Dispatch, KeyboardEvent, RefObject, SetStateAction } from 'react'
import { useCallback, useRef, useState, useEffect } from 'react'
import queryString from 'query-string'
import { useGetCachedRequest } from 'api'
import { useRouter, usePathname } from 'next/navigation'
import { useUpdateEffect } from '@chakra-ui/react'
import type { StorefrontSearchRes } from '../types'

export interface UseSearchReturnType {
  data?: StorefrontSearchRes['content'] | null
  preservedData?: StorefrontSearchRes['content'] | null
  isLoading: boolean
  error?: string
  searchQuery: string
  clearSearch: () => void
  isSearchPage: boolean
  inputRef: RefObject<HTMLInputElement>
  onKeyDown: (event: KeyboardEvent<HTMLInputElement>, callback?: () => void) => void
  submitAdvancedSearch: () => void
  advancedSearchQuery: string
  setAdvancedSearchQuery: Dispatch<SetStateAction<string>>
  searchPagePath: string
}

export interface UseSearchConfig {
  onSubmit?: (value: string) => void
  paths: {
    searchApi: string
    searchPage: string
  }
}

interface HookProps {
  config: UseSearchConfig
  size?: number
  delay?: number
}

const useSearch = ({ config, size, delay = 300 }: HookProps): UseSearchReturnType => {
  const pathname = usePathname()
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)
  const delayedSearchRef = useRef<number | undefined>()
  const [searchQuery, setSearchQuery] = useState('')
  const isSearchPage = !!pathname?.includes(config.paths.searchPage)
  const [advancedSearchQuery, setAdvancedSearchQuery] = useState('')

  const queryParams = queryString.stringify({
    query: searchQuery,
    ...(size ? { size } : {})
  })

  const { data, isLoading, error } = useGetCachedRequest<StorefrontSearchRes['content']>(
    searchQuery.length > 2 ? `${config.paths.searchApi}?${queryParams}` : null,
    'content'
  )

  const [preservedData, setPreservedData] = useState<
    StorefrontSearchRes['content'] | null | undefined
  >(data)

  useEffect(() => {
    if (!isLoading) {
      setPreservedData(data)
    }
  }, [data, isLoading])

  useUpdateEffect(() => {
    setAdvancedSearchQuery('')
  }, [searchQuery])

  const clearSearch = useCallback(() => {
    setSearchQuery('')

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])

  const goOnSearchPage = useCallback(
    (event?: KeyboardEvent<HTMLInputElement>, callback?: () => void) => {
      const value =
        (event ? (event.target as HTMLInputElement).value : inputRef.current?.value) || ''

      clearTimeout(delayedSearchRef.current)

      router.push(`${config.paths.searchPage}?q=${value}`)
      callback?.()
    },
    [config.paths.searchPage, router]
  )

  const submitAdvancedSearch = useCallback(() => {
    if (!searchQuery) {
      return
    }

    goOnSearchPage()
  }, [goOnSearchPage, searchQuery])

  const submitSearch = (event: KeyboardEvent<HTMLInputElement>, callback?: () => void) => {
    const value = (event.target as HTMLInputElement).value || ''
    setSearchQuery(value)

    if (!value) {
      return
    }

    // GA.search(value)

    if (event.key === 'Enter') {
      if (isSearchPage) {
        setAdvancedSearchQuery(value)
      }

      goOnSearchPage(event, callback)
    } else {
      config.onSubmit?.(value)
      callback?.()
    }
  }

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>, callback?: () => void) => {
    clearTimeout(delayedSearchRef.current)

    if (event.key === 'Enter') {
      submitSearch(event, callback)
      return
    }

    delayedSearchRef.current = setTimeout(() => {
      submitSearch(event, callback)
    }, delay) as unknown as number
  }

  return {
    onKeyDown,
    searchQuery,
    data,
    preservedData,
    isLoading,
    error,
    clearSearch,
    inputRef,
    isSearchPage,
    submitAdvancedSearch,
    advancedSearchQuery,
    setAdvancedSearchQuery,
    searchPagePath: config.paths.searchPage
  }
}

export default useSearch
