'use client'

import { type ReactNode, Suspense, type SuspenseProps } from 'react'
import { type ReadonlyURLSearchParams, useSearchParams } from 'next/navigation'

interface UseSearchParamsProps {
  children?: ((searchParams: ReadonlyURLSearchParams | null) => ReactNode) | ReactNode
}
const UseSearchParams = ({ children }: UseSearchParamsProps) => {
  const searchParams = useSearchParams()

  return typeof children === 'function' ? children(searchParams) : children
}

type UseSearchParamsSuspenseProps = UseSearchParamsProps & Omit<SuspenseProps, 'children'>

// https://nextjs.org/docs/messages/missing-suspense-with-csr-bailout
export const UseSearchParamsSuspense = ({ children, ...props }: UseSearchParamsSuspenseProps) => {
  return (
    <Suspense {...props}>
      <UseSearchParams>{children}</UseSearchParams>
    </Suspense>
  )
}
