import { FC } from 'react'
import { useModal } from '..'
import { CookiePolicy } from 'cookies'

interface CookiesModalProps {}

const ModalCookies: FC<CookiesModalProps> = ({}) => {
  const { closeModal } = useModal()

  return <CookiePolicy onConfig={closeModal} />
}

export default ModalCookies
