'use client'
import type { FC, ReactNode } from 'react'
import { fetchJson } from 'api'
import { SWRConfig } from 'swr'

interface SWRConfigClientProps {
  children: ReactNode
}

const SWRConfigClient: FC<SWRConfigClientProps> = ({ children }) => {
  return (
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnFocus: false,
        fetcher: fetchJson,
        onError: (err) => {
          console.error(err)
        }
      }}>
      {children}
    </SWRConfig>
  )
}

export default SWRConfigClient
