import { FC, KeyboardEvent } from 'react'
import useProductSearch from '../../../../../../packages/storefront-modules/products/hooks/useProductSearch'
import { useModal } from '#/src/custom/controllers'
import { SearchInput } from 'ui'

export interface IModalSearchProps {
  placeholder?: string
}

const ModalSearch: FC<IModalSearchProps> = ({ placeholder = 'Search...' }) => {
  const { closeModal } = useModal()
  const { onKeyDown } = useProductSearch()

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(event, closeModal)
  }

  return <SearchInput onKeyDown={handleKeyDown} {...{ placeholder }} />
}

export default ModalSearch
