'use client'
import { storefrontBaseStoreCreator } from 'shared-redux/state/store'
import { cookieSlice, CookieState } from 'cookies'
import { Reducer } from 'shared-redux'
import customProductSlice from './customProductSlice'
import { ProductsState } from 'shared-redux/state'

// Add the extensions paths and states to these types
type StoreStatePlugins = Reducer<CookieState> | Reducer<ProductsState>
type StoreReducerPaths = 'cookies' | 'products'

export const storeConfig = storefrontBaseStoreCreator<StoreStatePlugins, StoreReducerPaths>([
  // The store slices can be extended here.
  { reducerPath: 'cookies', reducer: cookieSlice },
  { reducerPath: 'products', reducer: customProductSlice }
])

export type CustomRootState = ReturnType<typeof storeConfig.getState>
