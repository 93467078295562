import storefrontApi from 'api/storefront-api'
import type { CustomerUpdateDTO, Language } from 'ecosystem'
import type { NextApiRequest, NextApiResponse } from 'next'
import { DEFAULT_PAG_SIZE } from 'pagination/constants'
import { useLocaleCredentials as setLocaleCredentials } from 'api'

export const orderDetailHandler = async (req: NextApiRequest, res: NextApiResponse) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (!req.session.user?.customerId) {
    res.status(401).json({ message: 'Unauthorized' })
    return
  }

  try {
    const { orderId } = req.query as { orderId?: string }

    if (!orderId) {
      res.status(401).json({ message: 'Missing params' })
      return
    }

    const response = await storefrontApi.customer.getCustomerOrderItems(
      req.session.user.customerId,
      orderId
    )
    res.json(response)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

export const ordersHandler = async (req: NextApiRequest, res: NextApiResponse) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (!req.session.user?.customerId) {
    res.status(401).json({ message: 'Unauthorized' })
    return
  }

  try {
    const { page, size } = req.query as { page?: string; size?: string }
    const response = await storefrontApi.customer.getCustomerOrders(req.session.user.customerId, {
      page: parseInt(page || '1'),
      size: parseInt(size || DEFAULT_PAG_SIZE.toString())
    })
    res.json(response)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}

export const updateCustomerHandler = async (req: NextApiRequest, res: NextApiResponse) => {
  const locale = (req.cookies.NEXT_LOCALE as Language) || 'sv'
  setLocaleCredentials(locale)
  if (!req.session.user?.customerId) {
    res.status(401).json({ message: 'Unauthorized' })
    return
  }

  try {
    const payload: CustomerUpdateDTO = await req.body
    const updatedCustomer = await storefrontApi.customer.update(
      req.session.user.customerId,
      payload
    )
    res.json(updatedCustomer)
  } catch (error) {
    res.status(500).json({ message: (error as Error).message })
  }
}
