import { createProductsSlice } from 'shared-redux/state'
import appConfig from '#/src/appConfig'

const defaultSorting = appConfig.sortingOptions.find((o) => o.default)

const customProductSlice = createProductsSlice({
  initialState: {
    priceRangeFilter: appConfig.sliderDefaultValues,
    ...(defaultSorting
      ? {
          sortBy: defaultSorting.sortBy,
          sortOrder: defaultSorting.sortOrder,
          sortLabel: defaultSorting.sortLabel
        }
      : {})
  }
})

export default customProductSlice.reducer
