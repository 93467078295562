'use client'
import { useState } from 'react'
import { VStack } from '@chakra-ui/react'
import type { ProfileFormType } from 'forms'
import { ProfileForm } from 'forms'
import type { CustomerDTO, CustomerUpdateDTO } from 'ecosystem'
import { Gender } from 'ecosystem'
import { useUser } from 'auth'
import { fetchJson } from 'api'
import { useAccountContext } from '../hooks/useAccountContext'

const AccountProfile = () => {
  const { user, mutateUser } = useUser()
  const { paths } = useAccountContext()
  const [errorMsg, setErrorMsg] = useState('')

  if (!user) {
    return null
  }

  const handleUpdateProfile = async (data: Partial<ProfileFormType>) => {
    try {
      const customerId = user.customer?.id

      if (!customerId) {
        setErrorMsg('Customer ID missing')
        return
      }
      const payload: CustomerUpdateDTO = {
        ...user.customer,
        addresses: user.customer?.addresses ?? null,
        phones: user.customer?.phones ?? null,
        dateOfBirth: user.customer?.dateOfBirth ?? null,
        firstName: user.customer?.firstName ?? null,
        lastName: user.customer?.lastName ?? null,
        middleName: user.customer?.middleName ?? null,
        gender: user.customer?.gender || Gender.UNDEFINED,
        organizationName: user.customer?.organizationName ?? null,
        ...data
      }

      const customer: CustomerDTO = await fetchJson(paths.updateCustomer, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      })
      await mutateUser({ ...user, customer })
      setErrorMsg('')
    } catch (e) {
      setErrorMsg((e as any).message)
    }
  }

  return (
    <VStack align="flex-start" justify="flex-start" spacing={12}>
      <ProfileForm
        data={user.customer}
        error={errorMsg}
        onFormSubmit={handleUpdateProfile}
        w={{
          base: 'full',
          md: '80%'
        }}
      />
    </VStack>
  )
}

export default AccountProfile
