'use client'
import Cookies from 'js-cookie'
import { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'shared-redux'
import { cookiesEnum } from '../types'
import { updateConsent } from '../cookieSlice'
import { selectedCookies } from '../selectors'

const useCookies = () => {
  const dispatch = useDispatch()

  //global state
  const { consent } = useSelector(selectedCookies)

  useEffect(() => {
    getConsent()
    //eslint-disable-next-line -- Only on mount
  }, [])

  // update the cons cookie and set state accordingly
  const setConsentAction = useCallback(
    (c: boolean) => {
      Cookies.set(cookiesEnum.consent, String(c), { expires: 365 })
      dispatch(updateConsent(c))
    },
    [dispatch]
  )

  // retrieve current cons cookie value and update state
  const getConsent = useCallback(() => {
    const c = Cookies.get(cookiesEnum.consent)
    dispatch(updateConsent(c === undefined ? undefined : c === 'true'))
    return c
  }, [dispatch])

  return {
    consent,
    setConsent: setConsentAction,
    getConsent
  }
}

export default useCookies
