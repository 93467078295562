import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import { HStack, Stack, VStack, WrapItem } from '@chakra-ui/react'
import { format } from 'date-fns'
import { commaSeparator } from 'shared-utils'
import { GrandText } from 'ui'
import type { OrderDTO } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'

interface CustomerOrderItemProps extends StackProps {
  customerOrder: OrderDTO
}

export interface CustomerOrderItemOverrides {
  customerOrderOrderNumber?: string
  customerOrderPaymentDate?: string
  customerOrderStatus?: string
  customerOrderShippingCost?: string
  customerOrderDiscount?: string
  customerOrderAmount?: string
  currency?: string
}

const CustomerOrderItem: FC<CustomerOrderItemProps> = ({ customerOrder, ...props }) => {
  const overrides = useOverridesContext<keyof CustomerOrderItemOverrides>()
  const orderInfoItems = [
    {
      label: overrideText('Ordernummer', overrides?.customerOrderOrderNumber),
      value: customerOrder.orderNumber
    },
    {
      label: overrideText('Beställingsdatum', overrides?.customerOrderPaymentDate),
      value:
        typeof customerOrder.created === 'number'
          ? format(new Date(customerOrder.created * 1000), 'yyy-MM-dd')
          : '-'
    },
    { label: overrideText('Status', overrides?.customerOrderStatus), value: customerOrder.status }
  ]

  const priceInfoItems = [
    {
      label: overrideText('Fraktkostnad', overrides?.customerOrderShippingCost),
      value: `${commaSeparator(customerOrder.shippingCost || 0)} ${overrideText(
        Currency.SEK,
        overrides?.currency
      )}`
    },
    {
      label: overrideText('Rabatt', overrides?.customerOrderDiscount),
      value: `${commaSeparator(customerOrder.orderDiscount || 0)} ${overrideText(
        Currency.SEK,
        overrides?.currency
      )}`
    },
    {
      label: overrideText('Belopp', overrides?.customerOrderAmount),
      value: `${commaSeparator(customerOrder.orderAmount || 0)} ${overrideText(
        Currency.SEK,
        overrides?.currency
      )}`
    }
  ]

  return (
    <Stack
      borderColor="text.mild"
      borderRadius="md"
      borderWidth="1px"
      flexDirection={{ base: 'column', md: 'row' }}
      p={2}
      w="full"
      {...props}>
      <WrapItem flex={1}>
        <VStack w="full">
          {orderInfoItems.map(({ label, value }, index) => (
            // eslint-disable-next-line react/no-array-index-key -- theres no id
            <OrderGroupItem key={index} {...{ label, value }} />
          ))}
        </VStack>
      </WrapItem>

      <WrapItem flex={1}>
        <VStack w="full">
          {priceInfoItems.map(({ label, value }, index) => (
            // eslint-disable-next-line react/no-array-index-key -- theres no id
            <OrderGroupItem key={index} {...{ label, value }} />
          ))}
        </VStack>
      </WrapItem>
    </Stack>
  )
}

export default CustomerOrderItem

export const OrderGroupItem = ({ label, value }: { label: string; value?: string | null }) => {
  return (
    <HStack w="full">
      <GrandText fontWeight="medium">{label}</GrandText>
      <GrandText fontSize="xs">{value || '-'}</GrandText>
    </HStack>
  )
}
