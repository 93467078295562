import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CookieState } from './types'

const initialState: CookieState = {
  consent: undefined
}

const slice = createSlice({
  name: 'cookies',
  initialState,
  reducers: {
    updateConsent: (state, { payload: consent }: PayloadAction<boolean | undefined>) => {
      state.consent = consent
      return state
    }
  }
})

// available actions - add more as needed, first they will have to be declared above
export const { updateConsent } = slice.actions

// exports slice reducer
export default slice.reducer
